import cx from 'classnames'
import { ActiveLink } from 'components/active-link'

// @ts-expect-error
import { ReactComponent as Twitter } from '/public/svgs/twitter.svg'
import styles from './footer.module.scss'

type FooterProps = {
  className?: string
  fixed?: boolean
}

export const Footer = ({ className, fixed }: FooterProps) => {
  return (
    <footer className={className}>
      <ul className={cx(styles.footer, fixed && styles.footer__fixed)}>
        <li>
          <ActiveLink href="/news">NEWS</ActiveLink>
        </li>
        <li>
          <ActiveLink href="/help">HELP</ActiveLink>
        </li>
        <li>
          <a
            aria-label="Twitter"
            className={styles.link}
            href="https://twitter.com/ProjectTempus_"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter alt="" aria-hidden="true" />
          </a>
        </li>
      </ul>
    </footer>
  )
}
